<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img :src="$store.state.app.logoImage"/>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Добро пожаловать!
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Логин/Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Логин/Email"
                  rules="required|min:5"
                >
                  <b-form-input
                    id="login-email"
                    v-model="login"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Пароль</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Запомнить
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="loading"
                  block
                  @click="validationForm"
                >
                  ВОЙТИ
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// eslint-disable-next-line no-unused-vars
import { required, min } from '@core/utils/validations/validations'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      login: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    if (this.$store.state.user.role !== null) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    // this.$store.commit('user/CLEAR')
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate()
        .then(success => {
          if (success) {
            this.fetchAccessToken()
          }
        })
    },
    fetchAccessToken() {
      this.loading = true
      this.$axios.post('/auth/login', {
        password: this.password,
        login: this.login,
      })
        .then(response => {
          if (response.status === 200) {
            if (this.status) {
              localStorage.setItem('accessToken', response.data.token)
            } else {
              sessionStorage.setItem('accessToken', response.data.token)
            }
            this.$store.dispatch('INIT_APP')
              .then(() => {
                if (this.$store.state.user.role) {
                  this.$router.push({ name: 'home' })
                }
              })
            /*
            Promise.all([
              this.$store.dispatch('user/SET_ACCESS_TOKEN', {
                access_token: response.data.token,
                remember: this.status,
              }),
              this.$store.dispatch('user/FETCH'),
            ]).then(() => {
              this.$router.push({ name: 'home' })
            })
            */
          }
        })
        .catch(() => {
          this.$bvToast.toast('Не верные email или пароль!', {
            title: 'Ошибка',
            autoHideDelay: 5000,
            variant: 'danger',
          })
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
